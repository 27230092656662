
export const Youtubeembed = (props) => {
  return (
    <div id='how2play'>
      <div id='portfolio' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>How to play</h2>
            <p>
            </p>
          </div>
          <div className='row'>
            <div className='portfolio-items' style={{
              "padding": "5px",
            }}>
              {props.data
                ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                    <div className="video-responsive">
                      <iframe
                        src={`https://www.youtube.com/embed/${d.id}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                  </div>
                ))
                : 'Loading...'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}