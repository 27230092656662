import React, { Component } from "react";
import ReactPlayer from "react-player";
import Modal from "react-modal";
// import './style.css';

export const Popup = (props) => {

  return (props.trigger) ? (
    <Modal isOpen={props.trigger}
      onRequestClose={() => ( props.setTrigger ? props.setTrigger(false) : "" )}
      style={{
        overlay: { zIndex: 100001, },
      }}>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => props.setTrigger(false)}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <ReactPlayer
        url="https://www.youtu.be/I3GYHzDpZ5g"
        width="100%"
        height="calc(110vh - 150px)"
      />
    </Modal >
  ) : "";

}



// import "react-modal-video/scss/modal-video.scss";

/*
export const Popup = (props) => {

  return (props.trigger) ? (
    <div>
      <div>
        <ModalVideo
          channel="youtube"
          isOpen={props.setTrigger}
          videoId="AIHlqEh0Bsc"
          onClose={() => props.setTrigger(false)}
        />
      </div>
    </div>

    // <div className="popup">
    //   <div className="popup-inner embed-responsive mbed-responsive-4by3">
    //     <div className="video-responsive embed-responsive-item">
    //       <iframe
    //         src={`https://www.youtube.com/embed/AIHlqEh0Bsc`}
    //         width="854"
    //         height="480"
    //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    //         allowFullScreen
    //         title="Embedded youtube"
    //       />
    //     </div>
    //     <button className="close-btn" onClick={() => props.setTrigger(false)} > close </button>
    //   </div>
    // </div>

  ) : "";

}
*/
