export const Image = ({ title, largeImage, smallImage }) => {
  return (
    <div className='portfolio-item'>
      <div className='hover-bg'>
        {' '}
        <a
          href={largeImage}
          title={title}
          data-lightbox-gallery='gallery1'
        >
          <div className='hover-text'>
            <h4>{title}</h4>
          </div>
          <img
            src={smallImage}
            style={{
              "width": "100%",
              "height" : "333px",
              "object-fit": "cover",
            }}
            className='img-responsive portfilio-img'
            alt={title}
          />{' '}
        </a>{' '}
      </div>
    </div>
  )
}