export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p>
            Design in mind, real toy kits in hearts and best experiences in all user time. We understand that user experiences should be entertaining and unique. Users can use our solution to light it up regardless where they are or who they are. GraphicsMiner lab members are ready to blend reality with technologies. 
          </p>
        </div>
        <div className="col-xs-12 col-md-12">
            {" "}
            <img src="img/portfolio/AvengerTeam.jpg" className="img-responsive team-img" alt="" />{" "}
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
