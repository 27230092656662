import { useState } from 'react';

export const Header = (props) => {
  // const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <button
                  onClick={
                    () => {
                      if (props.showDemo !== undefined) { props.showDemo(true); }
                    }
                  }
                  href='#features'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Watch our demo
                </button>
                <button
                  onClick={
                    () => {
                      window.open('https://graphicsminer.com/', '_blank');
                    }
                  }
                  href='#features'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Request a demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
